import { Pools } from '@/types/pools';

const pools: Pools = {
  IdsMap: {
    veBAL: '0xbf0fa44e5611c31429188b7dcc59ffe794d1980e000200000000000000000009',
  },
  Pagination: {
    PerPage: 15,
    PerPool: 15,
    PerPoolInitial: 5,
  },
  BoostsEnabled: true,
  DelegateOwner: '0xa29F1CA1957c164877F6A277C9791ACA3Ad4BD6D',
  ZeroAddress: '0x0000000000000000000000000000000000000000',
  DynamicFees: {
    Gauntlet: [],
  },
  BlockList: [
    '0x9023df6f6bcb1bae6298e6c783810bea75353659000000000000000000000005',
    '0xe79aa3ea990ad29a13a544d3ebfdb7bbc8a04ef1000200000000000000000007',
    '0xd757973e91a8045808e8cd37cc2b7df128e7ca2c00000000000000000000000d',
    '0xbb8a33dbbf10882d3d8d9180c56ff13ad6fd917d000200000000000000000011',
    '0x09ef3684052c0566caa6fc61008922030ff455b1000200000000000000000010',
    // '0x38c2609cabbe6cce553eb842b78ea59e5a73552800000000000000000000000e',
    '0x429d4ec4707734b7d9b82d1860202fcba2315481000200000000000000000017', //KINDS-SOULS
    '0x38c2609cabbe6cce553eb842b78ea59e5a73552800000000000000000000000e', //rfTLOS-STLOS
    '0x0fc39136421adfd8466e8909de8a7ecdf54f8d31000000000000000000000021', //myaUSDT
    '0x47355ba67a884fe9aa1b2cb774bf9128e7e0598a000000000000000000000020', //myaUSDC
    '0x73dfdf2b9a5ed7b032fa50dce81de350aa209aad000000000000000000000022', //myaUSDM
    '0x263639ba4b362de2673b482af2a8661f4d8fd610000000000000000000000025', // myaUSDM
    '0x934e800ea1ce2f04e1bd75028928404379d8f3f2000000000000000000000023', // myaUSDC
    '0xf6a33fd1086bae6fa913f5354fa1e15207361ac2000000000000000000000024', // myaUSDT
    '0x71fd3b5e53e444ed1e8309b578cffd7d33294c59000000000000000000000026', // myaUSDC v3
    '0xc8994727bf84b432a9955403e4335a874c1ae919000000000000000000000027', // myaUSDT v3
    '0x542a31176829f9dda137942c7cabbb4533523ad3000000000000000000000028', // myaUSDM v3
    '0xf0333afa20b852776911edb986061cef1376b4fe00000000000000000000002a', // myaUSD
    '0xceb2728bf37332291fa44891414a53b1d668578200020000000000000000001e', // ALI-SOULS
    '0x6e7d1985062b105ff4c210237354805cb67f99e500020000000000000000001f', // ALI-SUSD
    '0xfa5f3ba362577e35875e91eb3b16fbe7108f448600020000000000000000001d', // SOULS-SUSD
  ],
  IncludedPoolTypes: [
    'Weighted',
    'Stable',
    'MetaStable',
    'LiquidityBootstrapping',
    'Investment',
    'StablePhantom',
    'ComposableStable',
    'Managed',
  ],
  Stable: {
    AllowList: [
      '0x058d4893efa235d86cceed5a7eef0809b76c8c66000000000000000000000004',
      '0x9a77bd2edbbb68173275cda967b76e9213949ace000000000000000000000008',
      '0x5e99843486cf052baf0925a0cdeb40920477295900000000000000000000000b',
      // '0xd757973e91a8045808e8cd37cc2b7df128e7ca2c00000000000000000000000d',
      '0x412b37b8074e25683fdd4f5b2ac0218647dcc50e00000000000000000000001a', //wUSK-USDC
    ],
  },
  Investment: {
    AllowList: [],
  },
  Weighted: {
    // Only effective after given timestamp here: usePool.ts#createdAfterTimestamp
    // see useDisabledJoinPool.ts#nonAllowedWeightedPoolAfterTimestamp for logic.
    AllowList: [
      '0xbf0fa44e5611c31429188b7dcc59ffe794d1980e000200000000000000000009', //80TSYMM20WTLOS
      '0x2d714951f7165a51e8bd61f92d8a979ab0ed4b59000200000000000000000006',
      '0x0ca5d4b7aeeca61aff78c8f734596ec88456d5c300010000000000000000000a', // BTCb-ETH-USDC
      '0x2b014535525aad053b8811c22a337e57caae82df00020000000000000000000f', //WTLOS-USDC
      // '0xbb8a33dbbf10882d3d8d9180c56ff13ad6fd917d000200000000000000000011', //TSYMM-TSOUL
      // '0x09ef3684052c0566caa6fc61008922030ff455b1000200000000000000000010', //TKIND-TSOUL
      '0x0e4907910a6bd1a5e95500f7149dd57d328f65cb000200000000000000000012', //BTCb-STLOS
      '0x0485ecd8aa4e0624dd0f5da84139409ab7cee03c000200000000000000000013', //ETH-STLOS
      '0x304970d2511aa3b121148afd387cfa623f551410000200000000000000000016', //MST-USDM
      '0x5fc5f565d6e186a7e03b9ee58bdd551ebff0c0bd000200000000000000000014', //Trump-WTLOS,
      '0x6587a54645c39bc47c96e6f12052db347cc1003a000200000000000000000015', //CMDR-WTLOS
      '0xcf29825dfe41e62e218baa10b791a3d087fa7a83000200000000000000000018', //SOULS-TSYMM
      // '0x429d4ec4707734b7d9b82d1860202fcba2315481000200000000000000000017', //KINDS-SOULS
      '0xcacc06ea569e239d0e4b718e4da1b456d49e06f6000200000000000000000019', //KINDs-SOULS
      '0x03b038d9ad0a69339c9af310ac0f205e2670f9b200020000000000000000001b', //STLOS-wUSK
      '0x985b6de988a8f1124b5198913868dc2e441f529300020000000000000000002f', // TAPAS-WTLOS
    ],
  },
  Factories: {
    '0xdd58d43a829067129b8c37f1924d31b1896ac0c6': 'weightedPool', // Weighted V5
    '0x98add7dc34a382e188be709a72ca8fcdf7e548db': 'composableStablePool', // ComposableStable V5
    '0x4ed870363d69f0f2c565332342efc68ca82b544b': 'managedPool',
    '0x266b53bb135f2dbc324b5c5aa281e84f1c105b26': 'liquidityBootstrappingPool',
  },
  Stakable: {
    VotingGaugePools: [],
    AllowList: [
      '0x058d4893efa235d86cceed5a7eef0809b76c8c66000000000000000000000004',
      '0x9a77bd2edbbb68173275cda967b76e9213949ace000000000000000000000008',
      '0x2d714951f7165a51e8bd61f92d8a979ab0ed4b59000200000000000000000006',
      '0x0ca5d4b7aeeca61aff78c8f734596ec88456d5c300010000000000000000000a',
      '0x5e99843486cf052baf0925a0cdeb40920477295900000000000000000000000b',
      // '0xd757973e91a8045808e8cd37cc2b7df128e7ca2c00000000000000000000000d',
      '0x2b014535525aad053b8811c22a337e57caae82df00020000000000000000000f', //WTLOS-USDC
      '0x0e4907910a6bd1a5e95500f7149dd57d328f65cb000200000000000000000012', //BTCb-STLOS
      '0x0485ecd8aa4e0624dd0f5da84139409ab7cee03c000200000000000000000013', //ETH-STLOS
      '0x304970d2511aa3b121148afd387cfa623f551410000200000000000000000016', //MST-USDM
      '0x5fc5f565d6e186a7e03b9ee58bdd551ebff0c0bd000200000000000000000014', //Trump-WTLOS,
      '0x6587a54645c39bc47c96e6f12052db347cc1003a000200000000000000000015', //CMDR-WTLOS
      '0xcf29825dfe41e62e218baa10b791a3d087fa7a83000200000000000000000018', //SOULS-TSYMM
      '0xcacc06ea569e239d0e4b718e4da1b456d49e06f6000200000000000000000019', //KINDs-SOULS
      '0x03b038d9ad0a69339c9af310ac0f205e2670f9b200020000000000000000001b', //STLOS-wUSK
      '0x412b37b8074e25683fdd4f5b2ac0218647dcc50e00000000000000000000001a', //wUSK-USDC
    ],
  },
  Metadata: {
    '0x6fbfcf88db1aada31f34215b2a1df7fafb4883e900000000000000000000000c': {
      name: 'Symmetric Team Allocation',
      hasIcon: false,
    },
    '0x6fbfcf88db1aada31f34215b2a1df7fafb4883e900000000000000000000000d': {
      name: 'Burn tSYMM emissions',
      hasIcon: false,
    },
  },
  Deep: [
    '0x5e99843486cf052baf0925a0cdeb40920477295900000000000000000000000b', // USDM/USDC-USDT
  ],
  Deprecated: {
    '0x30f0797bbe89172b669467039d49d413eb09244b00020000000000000000001c': {
      suggestedPools: [
        '0xbf0fa44e5611c31429188b7dcc59ffe794d1980e000200000000000000000009', //tSYM-WTLOS
        '0x9a77bd2edbbb68173275cda967b76e9213949ace000000000000000000000008', //STLOS-WTLOS
        '0x2b014535525aad053b8811c22a337e57caae82df00020000000000000000000f', //WTLOS-USDC
        '0x2d714951f7165a51e8bd61f92d8a979ab0ed4b59000200000000000000000006', //WTLOS-USDT
        '0x058d4893efa235d86cceed5a7eef0809b76c8c66000000000000000000000004', //USDC-USDT
      ],
    }, // SOULS-WTLOS
    '0xfa5f3ba362577e35875e91eb3b16fbe7108f448600020000000000000000001d': {
      suggestedPools: [
        '0xbf0fa44e5611c31429188b7dcc59ffe794d1980e000200000000000000000009', //tSYM-WTLOS
        '0x9a77bd2edbbb68173275cda967b76e9213949ace000000000000000000000008', //STLOS-WTLOS
        '0x2b014535525aad053b8811c22a337e57caae82df00020000000000000000000f', //WTLOS-USDC
        '0x2d714951f7165a51e8bd61f92d8a979ab0ed4b59000200000000000000000006', //WTLOS-USDT
        '0x058d4893efa235d86cceed5a7eef0809b76c8c66000000000000000000000004', //USDC-USDT
      ],
    }, // SOULS-SUSD
    '0xceb2728bf37332291fa44891414a53b1d668578200020000000000000000001e': {
      suggestedPools: [
        '0xbf0fa44e5611c31429188b7dcc59ffe794d1980e000200000000000000000009', //tSYM-WTLOS
        '0x9a77bd2edbbb68173275cda967b76e9213949ace000000000000000000000008', //STLOS-WTLOS
        '0x2b014535525aad053b8811c22a337e57caae82df00020000000000000000000f', //WTLOS-USDC
        '0x2d714951f7165a51e8bd61f92d8a979ab0ed4b59000200000000000000000006', //WTLOS-USDT
        '0x058d4893efa235d86cceed5a7eef0809b76c8c66000000000000000000000004', //USDC-USDT
      ],
    }, // ALI-SOULS
    '0xcf29825dfe41e62e218baa10b791a3d087fa7a83000200000000000000000018': {
      suggestedPools: [
        '0xbf0fa44e5611c31429188b7dcc59ffe794d1980e000200000000000000000009', //tSYM-WTLOS
        '0x9a77bd2edbbb68173275cda967b76e9213949ace000000000000000000000008', //STLOS-WTLOS
        '0x2b014535525aad053b8811c22a337e57caae82df00020000000000000000000f', //WTLOS-USDC
        '0x2d714951f7165a51e8bd61f92d8a979ab0ed4b59000200000000000000000006', //WTLOS-USDT
        '0x058d4893efa235d86cceed5a7eef0809b76c8c66000000000000000000000004', //USDC-USDT
      ],
    }, //SOULS-TSYMM
    '0xcacc06ea569e239d0e4b718e4da1b456d49e06f6000200000000000000000019': {
      suggestedPools: [
        '0xbf0fa44e5611c31429188b7dcc59ffe794d1980e000200000000000000000009', //tSYM-WTLOS
        '0x9a77bd2edbbb68173275cda967b76e9213949ace000000000000000000000008', //STLOS-WTLOS
        '0x2b014535525aad053b8811c22a337e57caae82df00020000000000000000000f', //WTLOS-USDC
        '0x2d714951f7165a51e8bd61f92d8a979ab0ed4b59000200000000000000000006', //WTLOS-USDT
        '0x058d4893efa235d86cceed5a7eef0809b76c8c66000000000000000000000004', //USDC-USDT
      ],
    }, //KINDs-SOULS
    '0x6e7d1985062b105ff4c210237354805cb67f99e500020000000000000000001f': {
      suggestedPools: [
        '0xbf0fa44e5611c31429188b7dcc59ffe794d1980e000200000000000000000009', //tSYM-WTLOS
        '0x9a77bd2edbbb68173275cda967b76e9213949ace000000000000000000000008', //STLOS-WTLOS
        '0x2b014535525aad053b8811c22a337e57caae82df00020000000000000000000f', //WTLOS-USDC
        '0x2d714951f7165a51e8bd61f92d8a979ab0ed4b59000200000000000000000006', //WTLOS-USDT
        '0x058d4893efa235d86cceed5a7eef0809b76c8c66000000000000000000000004', //USDC-USDT
      ],
    }, // ALI-SUSD
  },
  GaugeMigration: {},
  BoostedApr: [],
  DisabledJoins: [
    '0xceb2728bf37332291fa44891414a53b1d668578200020000000000000000001e', // ALI-SOULS
    '0x6e7d1985062b105ff4c210237354805cb67f99e500020000000000000000001f', // ALI-SUSD
    '0xfa5f3ba362577e35875e91eb3b16fbe7108f448600020000000000000000001d', // SOULS-SUSD
    '0x30f0797bbe89172b669467039d49d413eb09244b00020000000000000000001c', // SOULS-WTLOS
    '0xcf29825dfe41e62e218baa10b791a3d087fa7a83000200000000000000000018', // SOULS-TSYMM
    '0xcacc06ea569e239d0e4b718e4da1b456d49e06f6000200000000000000000019', // KINDs-SOULS
  ],
  Issues: {},
};

export default pools;
