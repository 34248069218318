export const rewards = {
  1702512000: {
    '0x058d4893efa235d86cceed5a7eef0809b76c8c66000000000000000000000004': {
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0xe4c412962fab7f2d406c43a8da95c68f9d60f24e',
      rate: BigInt('6000000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
    '0x9a77bd2edbbb68173275cda967b76e9213949ace000000000000000000000008': {
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0xec678e92afe25bc5ba4bc2e1ff386c775270e49e',
      rate: BigInt('2000000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
    '0x2d714951f7165a51e8bd61f92d8a979ab0ed4b59000200000000000000000006': {
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0a9d436ab58989354a5c3705f24a5130779055e47',
      rate: BigInt('10000000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
  },
  1703116800: {
    '0x058d4893efa235d86cceed5a7eef0809b76c8c66000000000000000000000004': {
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0xe4c412962fab7f2d406c43a8da95c68f9d60f24e',
      rate: BigInt('6000000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
    '0x9a77bd2edbbb68173275cda967b76e9213949ace000000000000000000000008': {
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0xec678e92afe25bc5ba4bc2e1ff386c775270e49e',
      rate: BigInt('2000000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
    '0x2d714951f7165a51e8bd61f92d8a979ab0ed4b59000200000000000000000006': {
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0a9d436ab58989354a5c3705f24a5130779055e47',
      rate: BigInt('10000000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
    '0x0ca5d4b7aeeca61aff78c8f734596ec88456d5c300010000000000000000000a': {
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0x760dd84074f735bd3836718c0916a828d1dda502',
      rate: BigInt('6000000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
    '0x5e99843486cf052baf0925a0cdeb40920477295900000000000000000000000b': {
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0x6e0c092b93b5c075239ee61cae811924028899ea',
      rate: BigInt('2000000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
  },
  1703721600: {
    '0x058d4893efa235d86cceed5a7eef0809b76c8c66000000000000000000000004': {
      symbol: 'USDC-USDT',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0xe4c412962fab7f2d406c43a8da95c68f9d60f24e',
      rate: BigInt('2000000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
    '0x9a77bd2edbbb68173275cda967b76e9213949ace000000000000000000000008': {
      symbol: 'STLOS-WTLOS',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0xec678e92afe25bc5ba4bc2e1ff386c775270e49e',
      rate: BigInt('6000000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
    '0x2d714951f7165a51e8bd61f92d8a979ab0ed4b59000200000000000000000006': {
      symbol: 'WTLOS-USDT',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0a9d436ab58989354a5c3705f24a5130779055e47',
      rate: BigInt('10000000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
    '0x0ca5d4b7aeeca61aff78c8f734596ec88456d5c300010000000000000000000a': {
      symbol: 'BTCb-ETH-USDC',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0x760dd84074f735bd3836718c0916a828d1dda502',
      rate: BigInt('6000000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
    '0x5e99843486cf052baf0925a0cdeb40920477295900000000000000000000000b': {
      symbol: 'USDM/USDC-USDT',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0x6e0c092b93b5c075239ee61cae811924028899ea',
      rate: BigInt('2000000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
  },
  1704326400: {
    '0x058d4893efa235d86cceed5a7eef0809b76c8c66000000000000000000000004': {
      symbol: 'USDC-USDT',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0xe4c412962fab7f2d406c43a8da95c68f9d60f24e',
      rate: BigInt('3000000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
    '0x9a77bd2edbbb68173275cda967b76e9213949ace000000000000000000000008': {
      symbol: 'STLOS-WTLOS',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0xec678e92afe25bc5ba4bc2e1ff386c775270e49e',
      rate: BigInt('4000000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
    '0x2d714951f7165a51e8bd61f92d8a979ab0ed4b59000200000000000000000006': {
      symbol: 'WTLOS-USDT',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0xa9d436ab58989354a5c3705f24a5130779055e47',
      rate: BigInt('10000000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
    '0x0ca5d4b7aeeca61aff78c8f734596ec88456d5c300010000000000000000000a': {
      symbol: 'BTCb-ETH-USDC',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0x760dd84074f735bd3836718c0916a828d1dda502',
      rate: BigInt('6000000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
    '0x5e99843486cf052baf0925a0cdeb40920477295900000000000000000000000b': {
      symbol: 'USDM/USDC-USDT',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0x6e0c092b93b5c075239ee61cae811924028899ea',
      rate: BigInt('2500000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
  },
  1704931200: {
    '0x058d4893efa235d86cceed5a7eef0809b76c8c66000000000000000000000004': {
      symbol: 'USDC-USDT',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0xe4c412962fab7f2d406c43a8da95c68f9d60f24e',
      rate: BigInt('1500000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
    '0x9a77bd2edbbb68173275cda967b76e9213949ace000000000000000000000008': {
      symbol: 'STLOS-WTLOS',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0xec678e92afe25bc5ba4bc2e1ff386c775270e49e',
      rate: BigInt('2500000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
    '0x2d714951f7165a51e8bd61f92d8a979ab0ed4b59000200000000000000000006': {
      symbol: 'WTLOS-USDT',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0xa9d436ab58989354a5c3705f24a5130779055e47',
      rate: BigInt('10000000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
    '0x0ca5d4b7aeeca61aff78c8f734596ec88456d5c300010000000000000000000a': {
      symbol: 'BTCb-ETH-USDC',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0x760dd84074f735bd3836718c0916a828d1dda502',
      rate: BigInt('4000000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
    '0x5e99843486cf052baf0925a0cdeb40920477295900000000000000000000000b': {
      symbol: 'USDM/USDC-USDT',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0x6e0c092b93b5c075239ee61cae811924028899ea',
      rate: BigInt('2500000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
    '0xd757973e91a8045808e8cd37cc2b7df128e7ca2c00000000000000000000000d': {
      symbol: 'rfTLOS-STLOS',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0x08247c710d340d23adfd88ea80893519eaae9b4d',
      rate: BigInt('1000000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
  },
  1705536000: {
    '0x2d714951f7165a51e8bd61f92d8a979ab0ed4b59000200000000000000000006': {
      symbol: 'WTLOS-USDT',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0xa9d436ab58989354a5c3705f24a5130779055e47',
      rate: BigInt('5000000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
    '0x058d4893efa235d86cceed5a7eef0809b76c8c66000000000000000000000004': {
      symbol: 'USDC-USDT',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0xe4c412962fab7f2d406c43a8da95c68f9d60f24e',
      rate: BigInt('1500000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
    '0x9a77bd2edbbb68173275cda967b76e9213949ace000000000000000000000008': {
      symbol: 'STLOS-WTLOS',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0xec678e92afe25bc5ba4bc2e1ff386c775270e49e',
      rate: BigInt('3000000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
    '0x0ca5d4b7aeeca61aff78c8f734596ec88456d5c300010000000000000000000a': {
      symbol: 'BTCb-ETH-USDC',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0x760dd84074f735bd3836718c0916a828d1dda502',
      rate: BigInt('1000000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
    '0x5e99843486cf052baf0925a0cdeb40920477295900000000000000000000000b': {
      symbol: 'USDM/USDC-USDT',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0x6e0c092b93b5c075239ee61cae811924028899ea',
      rate: BigInt('1500000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
    '0x38c2609cabbe6cce553eb842b78ea59e5a73552800000000000000000000000e': {
      symbol: 'rfTLOS-STLOS',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0x5967e7572f925b5e133963ce792001148f57dc08',
      rate: BigInt('500000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
    '0x2b014535525aad053b8811c22a337e57caae82df00020000000000000000000f': {
      symbol: 'WTLOS-USDC',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0x9614933e16d6753ee9be1736b2850862984fdc3a',
      rate: BigInt('1000000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
  },
  1706140800: {
    '0x2d714951f7165a51e8bd61f92d8a979ab0ed4b59000200000000000000000006': {
      symbol: 'WTLOS-USDT',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0xa9d436ab58989354a5c3705f24a5130779055e47',
      rate: BigInt('3000000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
    '0x058d4893efa235d86cceed5a7eef0809b76c8c66000000000000000000000004': {
      symbol: 'USDC-USDT',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0xe4c412962fab7f2d406c43a8da95c68f9d60f24e',
      rate: BigInt('2000000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
    '0x9a77bd2edbbb68173275cda967b76e9213949ace000000000000000000000008': {
      symbol: 'STLOS-WTLOS',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0xec678e92afe25bc5ba4bc2e1ff386c775270e49e',
      rate: BigInt('3000000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
    '0x0ca5d4b7aeeca61aff78c8f734596ec88456d5c300010000000000000000000a': {
      symbol: 'BTCb-ETH-USDC',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0x760dd84074f735bd3836718c0916a828d1dda502',
      rate: BigInt('1000000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
    '0x5e99843486cf052baf0925a0cdeb40920477295900000000000000000000000b': {
      symbol: 'USDM/USDC-USDT',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0x6e0c092b93b5c075239ee61cae811924028899ea',
      rate: BigInt('2000000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
    '0x38c2609cabbe6cce553eb842b78ea59e5a73552800000000000000000000000e': {
      symbol: 'rfTLOS-STLOS',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0x5967e7572f925b5e133963ce792001148f57dc08',
      rate: BigInt('1000000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
    '0x2b014535525aad053b8811c22a337e57caae82df00020000000000000000000f': {
      symbol: 'WTLOS-USDC',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0x9614933e16d6753ee9be1736b2850862984fdc3a',
      rate: BigInt('1500000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
  },
  1706745600: {
    '0x2d714951f7165a51e8bd61f92d8a979ab0ed4b59000200000000000000000006': {
      symbol: 'WTLOS-USDT',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0xa9d436ab58989354a5c3705f24a5130779055e47',
      rate: BigInt('3000000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
    '0x058d4893efa235d86cceed5a7eef0809b76c8c66000000000000000000000004': {
      symbol: 'USDC-USDT',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0xe4c412962fab7f2d406c43a8da95c68f9d60f24e',
      rate: BigInt('2000000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
    '0x9a77bd2edbbb68173275cda967b76e9213949ace000000000000000000000008': {
      symbol: 'STLOS-WTLOS',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0xec678e92afe25bc5ba4bc2e1ff386c775270e49e',
      rate: BigInt('3000000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
    '0x0ca5d4b7aeeca61aff78c8f734596ec88456d5c300010000000000000000000a': {
      symbol: 'BTCb-ETH-USDC',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0x760dd84074f735bd3836718c0916a828d1dda502',
      rate: BigInt('1000000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
    '0x5e99843486cf052baf0925a0cdeb40920477295900000000000000000000000b': {
      symbol: 'USDM/USDC-USDT',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0x6e0c092b93b5c075239ee61cae811924028899ea',
      rate: BigInt('2000000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
    '0x38c2609cabbe6cce553eb842b78ea59e5a73552800000000000000000000000e': {
      symbol: 'rfTLOS-STLOS',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0x5967e7572f925b5e133963ce792001148f57dc08',
      rate: BigInt('1000000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
    '0x2b014535525aad053b8811c22a337e57caae82df00020000000000000000000f': {
      symbol: 'WTLOS-USDC',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0x9614933e16d6753ee9be1736b2850862984fdc3a',
      rate: BigInt('1500000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
  },
  1707350400: {
    '0x2d714951f7165a51e8bd61f92d8a979ab0ed4b59000200000000000000000006': {
      symbol: 'WTLOS-USDT',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0xa9d436ab58989354a5c3705f24a5130779055e47',
      rate: BigInt('3000000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
    '0x058d4893efa235d86cceed5a7eef0809b76c8c66000000000000000000000004': {
      symbol: 'USDC-USDT',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0xe4c412962fab7f2d406c43a8da95c68f9d60f24e',
      rate: BigInt('2000000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
    '0x9a77bd2edbbb68173275cda967b76e9213949ace000000000000000000000008': {
      symbol: 'STLOS-WTLOS',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0xec678e92afe25bc5ba4bc2e1ff386c775270e49e',
      rate: BigInt('3000000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
    '0x0ca5d4b7aeeca61aff78c8f734596ec88456d5c300010000000000000000000a': {
      symbol: 'BTCb-ETH-USDC',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0x760dd84074f735bd3836718c0916a828d1dda502',
      rate: BigInt('1000000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
    '0x5e99843486cf052baf0925a0cdeb40920477295900000000000000000000000b': {
      symbol: 'USDM/USDC-USDT',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0x6e0c092b93b5c075239ee61cae811924028899ea',
      rate: BigInt('2000000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
    '0x38c2609cabbe6cce553eb842b78ea59e5a73552800000000000000000000000e': {
      symbol: 'rfTLOS-STLOS',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0x5967e7572f925b5e133963ce792001148f57dc08',
      rate: BigInt('1000000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
    '0x2b014535525aad053b8811c22a337e57caae82df00020000000000000000000f': {
      symbol: 'WTLOS-USDC',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0x9614933e16d6753ee9be1736b2850862984fdc3a',
      rate: BigInt('1500000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
  },
  1707955200: {
    '0x2d714951f7165a51e8bd61f92d8a979ab0ed4b59000200000000000000000006': {
      symbol: 'WTLOS-USDT',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0xa9d436ab58989354a5c3705f24a5130779055e47',
      rate: BigInt('3000000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
    '0x058d4893efa235d86cceed5a7eef0809b76c8c66000000000000000000000004': {
      symbol: 'USDC-USDT',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0xe4c412962fab7f2d406c43a8da95c68f9d60f24e',
      rate: BigInt('2000000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
    '0x9a77bd2edbbb68173275cda967b76e9213949ace000000000000000000000008': {
      symbol: 'STLOS-WTLOS',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0xec678e92afe25bc5ba4bc2e1ff386c775270e49e',
      rate: BigInt('3000000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
    '0x0ca5d4b7aeeca61aff78c8f734596ec88456d5c300010000000000000000000a': {
      symbol: 'BTCb-ETH-USDC',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0x760dd84074f735bd3836718c0916a828d1dda502',
      rate: BigInt('1000000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
    '0x5e99843486cf052baf0925a0cdeb40920477295900000000000000000000000b': {
      symbol: 'USDM/USDC-USDT',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0x6e0c092b93b5c075239ee61cae811924028899ea',
      rate: BigInt('2000000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
    '0x38c2609cabbe6cce553eb842b78ea59e5a73552800000000000000000000000e': {
      symbol: 'rfTLOS-STLOS',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0x5967e7572f925b5e133963ce792001148f57dc08',
      rate: BigInt('1000000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
    '0x2b014535525aad053b8811c22a337e57caae82df00020000000000000000000f': {
      symbol: 'WTLOS-USDC',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0x9614933e16d6753ee9be1736b2850862984fdc3a',
      rate: BigInt('1500000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
  },
  1708560000: {
    '0x2d714951f7165a51e8bd61f92d8a979ab0ed4b59000200000000000000000006': {
      symbol: 'WTLOS-USDT',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0xa9d436ab58989354a5c3705f24a5130779055e47',
      rate: BigInt('2500000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
    '0x058d4893efa235d86cceed5a7eef0809b76c8c66000000000000000000000004': {
      symbol: 'USDC-USDT',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0xe4c412962fab7f2d406c43a8da95c68f9d60f24e',
      rate: BigInt('1750000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
    '0x9a77bd2edbbb68173275cda967b76e9213949ace000000000000000000000008': {
      symbol: 'STLOS-WTLOS',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0xec678e92afe25bc5ba4bc2e1ff386c775270e49e',
      rate: BigInt('4250000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
    '0x0ca5d4b7aeeca61aff78c8f734596ec88456d5c300010000000000000000000a': {
      symbol: 'BTCb-ETH-USDC',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0x760dd84074f735bd3836718c0916a828d1dda502',
      rate: BigInt('1000000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
    '0x5e99843486cf052baf0925a0cdeb40920477295900000000000000000000000b': {
      symbol: 'USDM/USDC-USDT',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0x6e0c092b93b5c075239ee61cae811924028899ea',
      rate: BigInt('1750000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
    '0x38c2609cabbe6cce553eb842b78ea59e5a73552800000000000000000000000e': {
      symbol: 'rfTLOS-STLOS',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0x5967e7572f925b5e133963ce792001148f57dc08',
      rate: BigInt('1000000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
    '0x2b014535525aad053b8811c22a337e57caae82df00020000000000000000000f': {
      symbol: 'WTLOS-USDC',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0x9614933e16d6753ee9be1736b2850862984fdc3a',
      rate: BigInt('1250000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
  },
  1709164800: {
    '0x2d714951f7165a51e8bd61f92d8a979ab0ed4b59000200000000000000000006': {
      symbol: 'WTLOS-USDT',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0xa9d436ab58989354a5c3705f24a5130779055e47',
      rate: BigInt('2000000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
    '0x058d4893efa235d86cceed5a7eef0809b76c8c66000000000000000000000004': {
      symbol: 'USDC-USDT',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0xe4c412962fab7f2d406c43a8da95c68f9d60f24e',
      rate: BigInt('1000000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
    '0x9a77bd2edbbb68173275cda967b76e9213949ace000000000000000000000008': {
      symbol: 'STLOS-WTLOS',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0xec678e92afe25bc5ba4bc2e1ff386c775270e49e',
      rate: BigInt('4500000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
    '0x0ca5d4b7aeeca61aff78c8f734596ec88456d5c300010000000000000000000a': {
      symbol: 'BTCb-ETH-USDC',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0x760dd84074f735bd3836718c0916a828d1dda502',
      rate: BigInt('750000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
    '0x5e99843486cf052baf0925a0cdeb40920477295900000000000000000000000b': {
      symbol: 'USDM/USDC-USDT',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0x6e0c092b93b5c075239ee61cae811924028899ea',
      rate: BigInt('1000000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
    '0x38c2609cabbe6cce553eb842b78ea59e5a73552800000000000000000000000e': {
      symbol: 'rfTLOS-STLOS',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0x5967e7572f925b5e133963ce792001148f57dc08',
      rate: BigInt('750000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
    '0x2b014535525aad053b8811c22a337e57caae82df00020000000000000000000f': {
      symbol: 'WTLOS-USDC',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0x9614933e16d6753ee9be1736b2850862984fdc3a',
      rate: BigInt('750000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
  },
  1709769600: {
    '0x2d714951f7165a51e8bd61f92d8a979ab0ed4b59000200000000000000000006': {
      symbol: 'WTLOS-USDT',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0xa9d436ab58989354a5c3705f24a5130779055e47',
      rate: BigInt('1250000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
    '0x058d4893efa235d86cceed5a7eef0809b76c8c66000000000000000000000004': {
      symbol: 'USDC-USDT',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0xe4c412962fab7f2d406c43a8da95c68f9d60f24e',
      rate: BigInt('750000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
    '0x9a77bd2edbbb68173275cda967b76e9213949ace000000000000000000000008': {
      symbol: 'STLOS-WTLOS',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0xec678e92afe25bc5ba4bc2e1ff386c775270e49e',
      rate: BigInt('4500000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
    // '0x0ca5d4b7aeeca61aff78c8f734596ec88456d5c300010000000000000000000a': {
    //   symbol: 'BTCb-ETH-USDC',
    //   token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
    //   tokenSymbol: 'WTLOS',
    //   gauge: '0x760dd84074f735bd3836718c0916a828d1dda502',
    //   rate: BigInt('750000000000000000000') / BigInt('604800'),
    //   period_finish: 0,
    //   decimals: 18,
    // },
    '0x5e99843486cf052baf0925a0cdeb40920477295900000000000000000000000b': {
      symbol: 'USDM/USDC-USDT',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0x6e0c092b93b5c075239ee61cae811924028899ea',
      rate: BigInt('750000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
    '0x38c2609cabbe6cce553eb842b78ea59e5a73552800000000000000000000000e': {
      symbol: 'rfTLOS-STLOS',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0x5967e7572f925b5e133963ce792001148f57dc08',
      rate: BigInt('250000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
    '0x2b014535525aad053b8811c22a337e57caae82df00020000000000000000000f': {
      symbol: 'WTLOS-USDC',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0x9614933e16d6753ee9be1736b2850862984fdc3a',
      rate: BigInt('500000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
    '0x0e4907910a6bd1a5e95500f7149dd57d328f65cb000200000000000000000012': {
      symbol: '50BTCb-50STLOS',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0xdf0bd977f7665a4d1d231d5cb68eb296c1ac9bbe',
      rate: BigInt('500000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
    '0x0485ecd8aa4e0624dd0f5da84139409ab7cee03c000200000000000000000013': {
      symbol: '50ETH-50STLOS',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0x99318500f2378d9ae4d69da4e4b006c79d828e69',
      rate: BigInt('500000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
  },
  1710374400: {
    '0x2d714951f7165a51e8bd61f92d8a979ab0ed4b59000200000000000000000006': {
      symbol: 'WTLOS-USDT',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0xa9d436ab58989354a5c3705f24a5130779055e47',
      rate: BigInt('500000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
    '0x058d4893efa235d86cceed5a7eef0809b76c8c66000000000000000000000004': {
      symbol: 'USDC-USDT',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0xe4c412962fab7f2d406c43a8da95c68f9d60f24e',
      rate: BigInt('500000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
    '0x9a77bd2edbbb68173275cda967b76e9213949ace000000000000000000000008': {
      symbol: 'STLOS-WTLOS',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0xec678e92afe25bc5ba4bc2e1ff386c775270e49e',
      rate: BigInt('2500000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
  },
  1710979200: {
    '0x2d714951f7165a51e8bd61f92d8a979ab0ed4b59000200000000000000000006': {
      symbol: 'WTLOS-USDT',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0xa9d436ab58989354a5c3705f24a5130779055e47',
      rate: BigInt('500000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
    '0x058d4893efa235d86cceed5a7eef0809b76c8c66000000000000000000000004': {
      symbol: 'USDC-USDT',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0xe4c412962fab7f2d406c43a8da95c68f9d60f24e',
      rate: BigInt('500000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
    '0x9a77bd2edbbb68173275cda967b76e9213949ace000000000000000000000008': {
      symbol: 'STLOS-WTLOS',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0xec678e92afe25bc5ba4bc2e1ff386c775270e49e',
      rate: BigInt('2000000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
  },
  1711584000: {
    '0x2d714951f7165a51e8bd61f92d8a979ab0ed4b59000200000000000000000006': {
      symbol: 'WTLOS-USDT',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0xa9d436ab58989354a5c3705f24a5130779055e47',
      rate: BigInt('500000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
    '0x058d4893efa235d86cceed5a7eef0809b76c8c66000000000000000000000004': {
      symbol: 'USDC-USDT',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0xe4c412962fab7f2d406c43a8da95c68f9d60f24e',
      rate: BigInt('500000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
    '0x9a77bd2edbbb68173275cda967b76e9213949ace000000000000000000000008': {
      symbol: 'STLOS-WTLOS',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0xec678e92afe25bc5ba4bc2e1ff386c775270e49e',
      rate: BigInt('2000000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
  },
  1712188800: {
    '0x2d714951f7165a51e8bd61f92d8a979ab0ed4b59000200000000000000000006': {
      symbol: 'WTLOS-USDT',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0xa9d436ab58989354a5c3705f24a5130779055e47',
      rate: BigInt('500000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
    '0x058d4893efa235d86cceed5a7eef0809b76c8c66000000000000000000000004': {
      symbol: 'USDC-USDT',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0xe4c412962fab7f2d406c43a8da95c68f9d60f24e',
      rate: BigInt('500000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
    '0x9a77bd2edbbb68173275cda967b76e9213949ace000000000000000000000008': {
      symbol: 'STLOS-WTLOS',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0xec678e92afe25bc5ba4bc2e1ff386c775270e49e',
      rate: BigInt('2000000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
  },
  1712793600: {
    '0x2d714951f7165a51e8bd61f92d8a979ab0ed4b59000200000000000000000006': {
      symbol: 'WTLOS-USDT',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0xa9d436ab58989354a5c3705f24a5130779055e47',
      rate: BigInt('500000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
    '0x058d4893efa235d86cceed5a7eef0809b76c8c66000000000000000000000004': {
      symbol: 'USDC-USDT',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0xe4c412962fab7f2d406c43a8da95c68f9d60f24e',
      rate: BigInt('500000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
    '0x9a77bd2edbbb68173275cda967b76e9213949ace000000000000000000000008': {
      symbol: 'STLOS-WTLOS',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0xec678e92afe25bc5ba4bc2e1ff386c775270e49e',
      rate: BigInt('3000000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
  },
  1714003200: {
    '0x2d714951f7165a51e8bd61f92d8a979ab0ed4b59000200000000000000000006': {
      symbol: 'WTLOS-USDT',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0xa9d436ab58989354a5c3705f24a5130779055e47',
      rate: BigInt('500000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
    '0x058d4893efa235d86cceed5a7eef0809b76c8c66000000000000000000000004': {
      symbol: 'USDC-USDT',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0xe4c412962fab7f2d406c43a8da95c68f9d60f24e',
      rate: BigInt('500000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
    '0x9a77bd2edbbb68173275cda967b76e9213949ace000000000000000000000008': {
      symbol: 'STLOS-WTLOS',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0xec678e92afe25bc5ba4bc2e1ff386c775270e49e',
      rate: BigInt('3000000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
  },
  1714608000: {
    '0x2d714951f7165a51e8bd61f92d8a979ab0ed4b59000200000000000000000006': {
      symbol: 'WTLOS-USDT',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0xa9d436ab58989354a5c3705f24a5130779055e47',
      rate: BigInt('500000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
    '0x058d4893efa235d86cceed5a7eef0809b76c8c66000000000000000000000004': {
      symbol: 'USDC-USDT',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0xe4c412962fab7f2d406c43a8da95c68f9d60f24e',
      rate: BigInt('500000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
    '0x9a77bd2edbbb68173275cda967b76e9213949ace000000000000000000000008': {
      symbol: 'STLOS-WTLOS',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0xec678e92afe25bc5ba4bc2e1ff386c775270e49e',
      rate: BigInt('3000000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
    '0x412b37b8074e25683fdd4f5b2ac0218647dcc50e00000000000000000000001a': {
      symbol: 'wUSK-USDC',
      token: '0x71a25e8494e6e2f986d7642ce4c06327056ce0a6',
      tokenSymbol: 'WTLOS',
      gauge: '0xe4c412962fab7f2d406c43a8da95c68f9d60f24e',
      rate: BigInt('6125000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
    '0x03b038d9ad0a69339c9af310ac0f205e2670f9b200020000000000000000001b': {
      symbol: 'STLOS-WTLOS',
      token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
      tokenSymbol: 'WTLOS',
      gauge: '0x30d9158f413937afa5693cb444174ca55f9e6b6c',
      rate: BigInt('2625000000000000000000') / BigInt('604800'),
      period_finish: 0,
      decimals: 18,
    },
  },
  1715212800: {
    '0x2d714951f7165a51e8bd61f92d8a979ab0ed4b59000200000000000000000006': [
      {
        symbol: 'WTLOS-USDT',
        token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
        tokenSymbol: 'WTLOS',
        gauge: '0xa9d436ab58989354a5c3705f24a5130779055e47',
        rate: BigInt('500000000000000000000') / BigInt('604800'),
        period_finish: 0,
        decimals: 18,
      },
    ],
    '0x058d4893efa235d86cceed5a7eef0809b76c8c66000000000000000000000004': [
      {
        symbol: 'USDC-USDT',
        token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
        tokenSymbol: 'WTLOS',
        gauge: '0xe4c412962fab7f2d406c43a8da95c68f9d60f24e',
        rate: BigInt('500000000000000000000') / BigInt('604800'),
        period_finish: 0,
        decimals: 18,
      },
    ],
    '0x9a77bd2edbbb68173275cda967b76e9213949ace000000000000000000000008': [
      {
        symbol: 'STLOS-WTLOS',
        token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
        tokenSymbol: 'WTLOS',
        gauge: '0xec678e92afe25bc5ba4bc2e1ff386c775270e49e',
        rate: BigInt('3000000000000000000000') / BigInt('604800'),
        period_finish: 0,
        decimals: 18,
      },
      // {
      //   symbol: 'STLOS-WTLOS',
      //   token: '0x8f7D64ea96D729EF24a0F30b4526D47b80d877B9',
      //   tokenSymbol: 'USDM',
      //   gauge: '0xec678e92afe25bc5ba4bc2e1ff386c775270e49e',
      //   rate: BigInt('3000000000000000000000') / BigInt('604800'),
      //   period_finish: 0,
      //   decimals: 18,
      // },
    ],
    '0x412b37b8074e25683fdd4f5b2ac0218647dcc50e00000000000000000000001a': [
      {
        symbol: 'wUSK-USDC',
        token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
        tokenSymbol: 'WTLOS',
        gauge: '0xe4c412962fab7f2d406c43a8da95c68f9d60f24e',
        rate: BigInt('6125000000000000000000') / BigInt('604800'),
        period_finish: 0,
        decimals: 18,
      },
    ],
    '0x03b038d9ad0a69339c9af310ac0f205e2670f9b200020000000000000000001b': [
      {
        symbol: 'STLOS-WTLOS',
        token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
        tokenSymbol: 'WTLOS',
        gauge: '0x30d9158f413937afa5693cb444174ca55f9e6b6c',
        rate: BigInt('2625000000000000000000') / BigInt('604800'),
        period_finish: 0,
        decimals: 18,
      },
    ],
  },
  1715817600: {
    '0x2d714951f7165a51e8bd61f92d8a979ab0ed4b59000200000000000000000006': [
      {
        symbol: 'WTLOS-USDT',
        token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
        tokenSymbol: 'WTLOS',
        gauge: '0xa9d436ab58989354a5c3705f24a5130779055e47',
        rate: BigInt('1000000000000000000000') / BigInt('604800'),
        period_finish: 0,
        decimals: 18,
      },
    ],
    '0x058d4893efa235d86cceed5a7eef0809b76c8c66000000000000000000000004': [
      {
        symbol: 'USDC-USDT',
        token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
        tokenSymbol: 'WTLOS',
        gauge: '0xe4c412962fab7f2d406c43a8da95c68f9d60f24e',
        rate: BigInt('750000000000000000000') / BigInt('604800'),
        period_finish: 0,
        decimals: 18,
      },
    ],
    '0x9a77bd2edbbb68173275cda967b76e9213949ace000000000000000000000008': [
      {
        symbol: 'STLOS-WTLOS',
        token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
        tokenSymbol: 'WTLOS',
        gauge: '0xec678e92afe25bc5ba4bc2e1ff386c775270e49e',
        rate: BigInt('2500000000000000000000') / BigInt('604800'),
        period_finish: 0,
        decimals: 18,
      },
      {
        symbol: 'STLOS-WTLOS',
        token: '0x8f7D64ea96D729EF24a0F30b4526D47b80d877B9',
        tokenSymbol: 'USDM',
        gauge: '0xec678e92afe25bc5ba4bc2e1ff386c775270e49e',
        rate: BigInt('125000000000000000000') / BigInt('604800'),
        period_finish: 0,
        decimals: 18,
      },
    ],
    '0x412b37b8074e25683fdd4f5b2ac0218647dcc50e00000000000000000000001a': [
      {
        symbol: 'wUSK-USDC',
        token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
        tokenSymbol: 'WTLOS',
        gauge: '0xe4c412962fab7f2d406c43a8da95c68f9d60f24e',
        rate: BigInt('6125000000000000000000') / BigInt('604800'),
        period_finish: 0,
        decimals: 18,
      },
    ],
    '0x03b038d9ad0a69339c9af310ac0f205e2670f9b200020000000000000000001b': [
      {
        symbol: 'STLOS-WTLOS',
        token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
        tokenSymbol: 'WTLOS',
        gauge: '0x30d9158f413937afa5693cb444174ca55f9e6b6c',
        rate: BigInt('2625000000000000000000') / BigInt('604800'),
        period_finish: 0,
        decimals: 18,
      },
    ],
  },
  1716422400: {
    '0x2d714951f7165a51e8bd61f92d8a979ab0ed4b59000200000000000000000006': [
      {
        symbol: 'WTLOS-USDT',
        token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
        tokenSymbol: 'WTLOS',
        gauge: '0xa9d436ab58989354a5c3705f24a5130779055e47',
        rate: BigInt('1000000000000000000000') / BigInt('604800'),
        period_finish: 0,
        decimals: 18,
      },
    ],
    '0x058d4893efa235d86cceed5a7eef0809b76c8c66000000000000000000000004': [
      {
        symbol: 'USDC-USDT',
        token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
        tokenSymbol: 'WTLOS',
        gauge: '0xe4c412962fab7f2d406c43a8da95c68f9d60f24e',
        rate: BigInt('750000000000000000000') / BigInt('604800'),
        period_finish: 0,
        decimals: 18,
      },
    ],
    '0x9a77bd2edbbb68173275cda967b76e9213949ace000000000000000000000008': [
      {
        symbol: 'STLOS-WTLOS',
        token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
        tokenSymbol: 'WTLOS',
        gauge: '0xec678e92afe25bc5ba4bc2e1ff386c775270e49e',
        rate: BigInt('2500000000000000000000') / BigInt('604800'),
        period_finish: 0,
        decimals: 18,
      },
      {
        symbol: 'STLOS-WTLOS',
        token: '0x8f7D64ea96D729EF24a0F30b4526D47b80d877B9',
        tokenSymbol: 'USDM',
        gauge: '0xec678e92afe25bc5ba4bc2e1ff386c775270e49e',
        rate: BigInt('125000000000000000000') / BigInt('604800'),
        period_finish: 0,
        decimals: 18,
      },
    ],
    '0x412b37b8074e25683fdd4f5b2ac0218647dcc50e00000000000000000000001a': [
      {
        symbol: 'wUSK-USDC',
        token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
        tokenSymbol: 'WTLOS',
        gauge: '0xe4c412962fab7f2d406c43a8da95c68f9d60f24e',
        rate: BigInt('6125000000000000000000') / BigInt('604800'),
        period_finish: 0,
        decimals: 18,
      },
    ],
    '0x03b038d9ad0a69339c9af310ac0f205e2670f9b200020000000000000000001b': [
      {
        symbol: 'STLOS-WTLOS',
        token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
        tokenSymbol: 'WTLOS',
        gauge: '0x30d9158f413937afa5693cb444174ca55f9e6b6c',
        rate: BigInt('2625000000000000000000') / BigInt('604800'),
        period_finish: 0,
        decimals: 18,
      },
    ],
  },
  1717027200: {
    '0x2d714951f7165a51e8bd61f92d8a979ab0ed4b59000200000000000000000006': [
      {
        symbol: 'WTLOS-USDT',
        token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
        tokenSymbol: 'WTLOS',
        gauge: '0xa9d436ab58989354a5c3705f24a5130779055e47',
        rate: BigInt('1000000000000000000000') / BigInt('604800'),
        period_finish: 0,
        decimals: 18,
      },
    ],
    '0x058d4893efa235d86cceed5a7eef0809b76c8c66000000000000000000000004': [
      {
        symbol: 'USDC-USDT',
        token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
        tokenSymbol: 'WTLOS',
        gauge: '0xe4c412962fab7f2d406c43a8da95c68f9d60f24e',
        rate: BigInt('750000000000000000000') / BigInt('604800'),
        period_finish: 0,
        decimals: 18,
      },
    ],
    '0x9a77bd2edbbb68173275cda967b76e9213949ace000000000000000000000008': [
      {
        symbol: 'STLOS-WTLOS',
        token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
        tokenSymbol: 'WTLOS',
        gauge: '0xec678e92afe25bc5ba4bc2e1ff386c775270e49e',
        rate: BigInt('2500000000000000000000') / BigInt('604800'),
        period_finish: 0,
        decimals: 18,
      },
      {
        symbol: 'STLOS-WTLOS',
        token: '0x8f7D64ea96D729EF24a0F30b4526D47b80d877B9',
        tokenSymbol: 'USDM',
        gauge: '0xec678e92afe25bc5ba4bc2e1ff386c775270e49e',
        rate: BigInt('125000000000000000000') / BigInt('604800'),
        period_finish: 0,
        decimals: 18,
      },
    ],
    '0x412b37b8074e25683fdd4f5b2ac0218647dcc50e00000000000000000000001a': [
      {
        symbol: 'wUSK-USDC',
        token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
        tokenSymbol: 'WTLOS',
        gauge: '0xe4c412962fab7f2d406c43a8da95c68f9d60f24e',
        rate: BigInt('6778333333333330000000') / BigInt('604800'),
        period_finish: 0,
        decimals: 18,
      },
    ],
    '0x03b038d9ad0a69339c9af310ac0f205e2670f9b200020000000000000000001b': [
      {
        symbol: 'wUSK-STLOS',
        token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
        tokenSymbol: 'WTLOS',
        gauge: '0x30d9158f413937afa5693cb444174ca55f9e6b6c',
        rate: BigInt('2905000000000000000000') / BigInt('604800'),
        period_finish: 0,
        decimals: 18,
      },
    ],
  },
  1717632000: {
    '0x2d714951f7165a51e8bd61f92d8a979ab0ed4b59000200000000000000000006': [
      {
        symbol: 'WTLOS-USDT',
        token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
        tokenSymbol: 'WTLOS',
        gauge: '0xa9d436ab58989354a5c3705f24a5130779055e47',
        rate: BigInt('1000000000000000000000') / BigInt('604800'),
        period_finish: 0,
        decimals: 18,
      },
    ],
    '0x058d4893efa235d86cceed5a7eef0809b76c8c66000000000000000000000004': [
      {
        symbol: 'USDC-USDT',
        token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
        tokenSymbol: 'WTLOS',
        gauge: '0xe4c412962fab7f2d406c43a8da95c68f9d60f24e',
        rate: BigInt('750000000000000000000') / BigInt('604800'),
        period_finish: 0,
        decimals: 18,
      },
    ],
    '0x9a77bd2edbbb68173275cda967b76e9213949ace000000000000000000000008': [
      {
        symbol: 'STLOS-WTLOS',
        token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
        tokenSymbol: 'WTLOS',
        gauge: '0xec678e92afe25bc5ba4bc2e1ff386c775270e49e',
        rate: BigInt('2500000000000000000000') / BigInt('604800'),
        period_finish: 0,
        decimals: 18,
      },
      {
        symbol: 'STLOS-WTLOS',
        token: '0x8f7D64ea96D729EF24a0F30b4526D47b80d877B9',
        tokenSymbol: 'USDM',
        gauge: '0xec678e92afe25bc5ba4bc2e1ff386c775270e49e',
        rate: BigInt('125000000000000000000') / BigInt('604800'),
        period_finish: 0,
        decimals: 18,
      },
    ],
    '0x412b37b8074e25683fdd4f5b2ac0218647dcc50e00000000000000000000001a': [
      {
        symbol: 'wUSK-USDC',
        token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
        tokenSymbol: 'WTLOS',
        gauge: '0xe4c412962fab7f2d406c43a8da95c68f9d60f24e',
        rate: BigInt('6778333333333330000000') / BigInt('604800'),
        period_finish: 0,
        decimals: 18,
      },
    ],
    '0x03b038d9ad0a69339c9af310ac0f205e2670f9b200020000000000000000001b': [
      {
        symbol: 'wUSK-STLOS',
        token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
        tokenSymbol: 'WTLOS',
        gauge: '0x30d9158f413937afa5693cb444174ca55f9e6b6c',
        rate: BigInt('2905000000000000000000') / BigInt('604800'),
        period_finish: 0,
        decimals: 18,
      },
    ],
  },
  1718236800: {
    '0x2d714951f7165a51e8bd61f92d8a979ab0ed4b59000200000000000000000006': [
      {
        symbol: 'WTLOS-USDT',
        token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
        tokenSymbol: 'WTLOS',
        gauge: '0xa9d436ab58989354a5c3705f24a5130779055e47',
        rate: BigInt('1019200000000000000000') / BigInt('604800'),
        period_finish: 0,
        decimals: 18,
      },
    ],
    '0x058d4893efa235d86cceed5a7eef0809b76c8c66000000000000000000000004': [
      {
        symbol: 'USDC-USDT',
        token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
        tokenSymbol: 'WTLOS',
        gauge: '0xe4c412962fab7f2d406c43a8da95c68f9d60f24e',
        rate: BigInt('764400000000000000000') / BigInt('604800'),
        period_finish: 0,
        decimals: 18,
      },
      {
        symbol: 'USDC-USDT',
        token: '0x8f7D64ea96D729EF24a0F30b4526D47b80d877B9',
        tokenSymbol: 'USDM',
        gauge: '0xe4c412962fab7f2d406c43a8da95c68f9d60f24e',
        rate: BigInt('93333333333333300000') / BigInt('604800'),
        period_finish: 0,
        decimals: 18,
      },
    ],
    '0x9a77bd2edbbb68173275cda967b76e9213949ace000000000000000000000008': [
      {
        symbol: 'STLOS-WTLOS',
        token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
        tokenSymbol: 'WTLOS',
        gauge: '0xec678e92afe25bc5ba4bc2e1ff386c775270e49e',
        rate: BigInt('2548000000000000000000') / BigInt('604800'),
        period_finish: 0,
        decimals: 18,
      },
      {
        symbol: 'STLOS-WTLOS',
        token: '0x8f7D64ea96D729EF24a0F30b4526D47b80d877B9',
        tokenSymbol: 'USDM',
        gauge: '0xec678e92afe25bc5ba4bc2e1ff386c775270e49e',
        rate: BigInt('186666666666667000000') / BigInt('604800'),
        period_finish: 0,
        decimals: 18,
      },
    ],
    '0x412b37b8074e25683fdd4f5b2ac0218647dcc50e00000000000000000000001a': [
      {
        symbol: 'wUSK-USDC',
        token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
        tokenSymbol: 'WTLOS',
        gauge: '0xe4c412962fab7f2d406c43a8da95c68f9d60f24e',
        rate: BigInt('6778333333333330000000') / BigInt('604800'),
        period_finish: 0,
        decimals: 18,
      },
    ],
    '0x03b038d9ad0a69339c9af310ac0f205e2670f9b200020000000000000000001b': [
      {
        symbol: 'wUSK-STLOS',
        token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
        tokenSymbol: 'WTLOS',
        gauge: '0x30d9158f413937afa5693cb444174ca55f9e6b6c',
        rate: BigInt('2905000000000000000000') / BigInt('604800'),
        period_finish: 0,
        decimals: 18,
      },
    ],
  },
  1718841600: {
    '0x2d714951f7165a51e8bd61f92d8a979ab0ed4b59000200000000000000000006': [
      {
        symbol: 'WTLOS-USDT',
        token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
        tokenSymbol: 'WTLOS',
        gauge: '0xa9d436ab58989354a5c3705f24a5130779055e47',
        rate: BigInt('1019200000000000000000') / BigInt('604800'),
        period_finish: 0,
        decimals: 18,
      },
    ],
    '0x058d4893efa235d86cceed5a7eef0809b76c8c66000000000000000000000004': [
      {
        symbol: 'USDC-USDT',
        token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
        tokenSymbol: 'WTLOS',
        gauge: '0xe4c412962fab7f2d406c43a8da95c68f9d60f24e',
        rate: BigInt('764400000000000000000') / BigInt('604800'),
        period_finish: 0,
        decimals: 18,
      },
      {
        symbol: 'USDC-USDT',
        token: '0x8f7D64ea96D729EF24a0F30b4526D47b80d877B9',
        tokenSymbol: 'USDM',
        gauge: '0xe4c412962fab7f2d406c43a8da95c68f9d60f24e',
        rate: BigInt('93333333333333300000') / BigInt('604800'),
        period_finish: 0,
        decimals: 18,
      },
    ],
    '0x9a77bd2edbbb68173275cda967b76e9213949ace000000000000000000000008': [
      {
        symbol: 'STLOS-WTLOS',
        token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
        tokenSymbol: 'WTLOS',
        gauge: '0xec678e92afe25bc5ba4bc2e1ff386c775270e49e',
        rate: BigInt('2548000000000000000000') / BigInt('604800'),
        period_finish: 0,
        decimals: 18,
      },
      {
        symbol: 'STLOS-WTLOS',
        token: '0x8f7D64ea96D729EF24a0F30b4526D47b80d877B9',
        tokenSymbol: 'USDM',
        gauge: '0xec678e92afe25bc5ba4bc2e1ff386c775270e49e',
        rate: BigInt('186666666666667000000') / BigInt('604800'),
        period_finish: 0,
        decimals: 18,
      },
    ],
    '0x412b37b8074e25683fdd4f5b2ac0218647dcc50e00000000000000000000001a': [
      {
        symbol: 'wUSK-USDC',
        token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
        tokenSymbol: 'WTLOS',
        gauge: '0xe4c412962fab7f2d406c43a8da95c68f9d60f24e',
        rate: BigInt('6778333333333330000000') / BigInt('604800'),
        period_finish: 0,
        decimals: 18,
      },
    ],
    '0x03b038d9ad0a69339c9af310ac0f205e2670f9b200020000000000000000001b': [
      {
        symbol: 'wUSK-STLOS',
        token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
        tokenSymbol: 'WTLOS',
        gauge: '0x30d9158f413937afa5693cb444174ca55f9e6b6c',
        rate: BigInt('2905000000000000000000') / BigInt('604800'),
        period_finish: 0,
        decimals: 18,
      },
    ],
  },
  1719446400: {
    '0x2d714951f7165a51e8bd61f92d8a979ab0ed4b59000200000000000000000006': [
      {
        symbol: 'WTLOS-USDT',
        token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
        tokenSymbol: 'WTLOS',
        gauge: '0xa9d436ab58989354a5c3705f24a5130779055e47',
        rate: BigInt('1019200000000000000000') / BigInt('604800'),
        period_finish: 0,
        decimals: 18,
      },
    ],
    '0x058d4893efa235d86cceed5a7eef0809b76c8c66000000000000000000000004': [
      {
        symbol: 'USDC-USDT',
        token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
        tokenSymbol: 'WTLOS',
        gauge: '0xe4c412962fab7f2d406c43a8da95c68f9d60f24e',
        rate: BigInt('764400000000000000000') / BigInt('604800'),
        period_finish: 0,
        decimals: 18,
      },
      {
        symbol: 'USDC-USDT',
        token: '0x8f7D64ea96D729EF24a0F30b4526D47b80d877B9',
        tokenSymbol: 'USDM',
        gauge: '0xe4c412962fab7f2d406c43a8da95c68f9d60f24e',
        rate: BigInt('93333333333333300000') / BigInt('604800'),
        period_finish: 0,
        decimals: 18,
      },
    ],
    '0x9a77bd2edbbb68173275cda967b76e9213949ace000000000000000000000008': [
      {
        symbol: 'STLOS-WTLOS',
        token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
        tokenSymbol: 'WTLOS',
        gauge: '0xec678e92afe25bc5ba4bc2e1ff386c775270e49e',
        rate: BigInt('2548000000000000000000') / BigInt('604800'),
        period_finish: 0,
        decimals: 18,
      },
      {
        symbol: 'STLOS-WTLOS',
        token: '0x8f7D64ea96D729EF24a0F30b4526D47b80d877B9',
        tokenSymbol: 'USDM',
        gauge: '0xec678e92afe25bc5ba4bc2e1ff386c775270e49e',
        rate: BigInt('186666666666667000000') / BigInt('604800'),
        period_finish: 0,
        decimals: 18,
      },
    ],
    '0x412b37b8074e25683fdd4f5b2ac0218647dcc50e00000000000000000000001a': [
      {
        symbol: 'wUSK-USDC',
        token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
        tokenSymbol: 'WTLOS',
        gauge: '0xe4c412962fab7f2d406c43a8da95c68f9d60f24e',
        rate: BigInt('6778333333333330000000') / BigInt('604800'),
        period_finish: 0,
        decimals: 18,
      },
    ],
    '0x03b038d9ad0a69339c9af310ac0f205e2670f9b200020000000000000000001b': [
      {
        symbol: 'wUSK-STLOS',
        token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
        tokenSymbol: 'WTLOS',
        gauge: '0x30d9158f413937afa5693cb444174ca55f9e6b6c',
        rate: BigInt('2905000000000000000000') / BigInt('604800'),
        period_finish: 0,
        decimals: 18,
      },
    ],
  },
  1720051200: {
    '0x2d714951f7165a51e8bd61f92d8a979ab0ed4b59000200000000000000000006': [
      {
        symbol: 'WTLOS-USDT',
        token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
        tokenSymbol: 'WTLOS',
        gauge: '0xa9d436ab58989354a5c3705f24a5130779055e47',
        rate: BigInt('947940708393602000000') / BigInt('604800'),
        period_finish: 0,
        decimals: 18,
      },
    ],
    '0x058d4893efa235d86cceed5a7eef0809b76c8c66000000000000000000000004': [
      {
        symbol: 'USDC-USDT',
        token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
        tokenSymbol: 'WTLOS',
        gauge: '0xe4c412962fab7f2d406c43a8da95c68f9d60f24e',
        rate: BigInt('710955531295201000000') / BigInt('604800'),
        period_finish: 0,
        decimals: 18,
      },
      {
        symbol: 'USDC-USDT',
        token: '0x8f7D64ea96D729EF24a0F30b4526D47b80d877B9',
        tokenSymbol: 'USDM',
        gauge: '0xe4c412962fab7f2d406c43a8da95c68f9d60f24e',
        rate: BigInt('93333333333333300000') / BigInt('604800'),
        period_finish: 0,
        decimals: 18,
      },
    ],
    '0x9a77bd2edbbb68173275cda967b76e9213949ace000000000000000000000008': [
      {
        symbol: 'STLOS-WTLOS',
        token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
        tokenSymbol: 'WTLOS',
        gauge: '0xec678e92afe25bc5ba4bc2e1ff386c775270e49e',
        rate: BigInt('2369851770984000000000') / BigInt('604800'),
        period_finish: 0,
        decimals: 18,
      },
      {
        symbol: 'STLOS-WTLOS',
        token: '0x8f7D64ea96D729EF24a0F30b4526D47b80d877B9',
        tokenSymbol: 'USDM',
        gauge: '0xec678e92afe25bc5ba4bc2e1ff386c775270e49e',
        rate: BigInt('186666666666667000000') / BigInt('604800'),
        period_finish: 0,
        decimals: 18,
      },
    ],
  },
  1721260800: {
    '0x2d714951f7165a51e8bd61f92d8a979ab0ed4b59000200000000000000000006': [
      {
        symbol: 'WTLOS-USDT',
        token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
        tokenSymbol: 'WTLOS',
        gauge: '0xa9d436ab58989354a5c3705f24a5130779055e47',
        rate: BigInt('750000000000000000000') / BigInt('604800'),
        period_finish: 0,
        decimals: 18,
      },
    ],
    '0x058d4893efa235d86cceed5a7eef0809b76c8c66000000000000000000000004': [
      {
        symbol: 'USDC-USDT',
        token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
        tokenSymbol: 'WTLOS',
        gauge: '0xe4c412962fab7f2d406c43a8da95c68f9d60f24e',
        rate: BigInt('10000000000000000000') / BigInt('604800'),
        period_finish: 0,
        decimals: 18,
      },
    ],
    '0x9a77bd2edbbb68173275cda967b76e9213949ace000000000000000000000008': [
      {
        symbol: 'STLOS-WTLOS',
        token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
        tokenSymbol: 'WTLOS',
        gauge: '0xec678e92afe25bc5ba4bc2e1ff386c775270e49e',
        rate: BigInt('2000000000000000000000') / BigInt('604800'),
        period_finish: 0,
        decimals: 18,
      },
      {
        symbol: 'STLOS-WTLOS',
        token: '0x8f7D64ea96D729EF24a0F30b4526D47b80d877B9',
        tokenSymbol: 'USDM',
        gauge: '0xec678e92afe25bc5ba4bc2e1ff386c775270e49e',
        rate: BigInt('400000000000000000000') / BigInt('604800'),
        period_finish: 0,
        decimals: 18,
      },
    ],
  },
  1721865600: {
    '0x2d714951f7165a51e8bd61f92d8a979ab0ed4b59000200000000000000000006': [
      {
        symbol: 'WTLOS-USDT',
        token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
        tokenSymbol: 'WTLOS',
        gauge: '0xa9d436ab58989354a5c3705f24a5130779055e47',
        rate: BigInt('750000000000000000000') / BigInt('604800'),
        period_finish: 0,
        decimals: 18,
      },
    ],
    '0x058d4893efa235d86cceed5a7eef0809b76c8c66000000000000000000000004': [
      {
        symbol: 'USDC-USDT',
        token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
        tokenSymbol: 'WTLOS',
        gauge: '0xe4c412962fab7f2d406c43a8da95c68f9d60f24e',
        rate: BigInt('10000000000000000000') / BigInt('604800'),
        period_finish: 0,
        decimals: 18,
      },
    ],
    '0x9a77bd2edbbb68173275cda967b76e9213949ace000000000000000000000008': [
      {
        symbol: 'STLOS-WTLOS',
        token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
        tokenSymbol: 'WTLOS',
        gauge: '0xec678e92afe25bc5ba4bc2e1ff386c775270e49e',
        rate: BigInt('2000000000000000000000') / BigInt('604800'),
        period_finish: 0,
        decimals: 18,
      },
      {
        symbol: 'STLOS-WTLOS',
        token: '0x8f7D64ea96D729EF24a0F30b4526D47b80d877B9',
        tokenSymbol: 'USDM',
        gauge: '0xec678e92afe25bc5ba4bc2e1ff386c775270e49e',
        rate: BigInt('400000000000000000000') / BigInt('604800'),
        period_finish: 0,
        decimals: 18,
      },
    ],
  },
  1723680000: {
    '0x2d714951f7165a51e8bd61f92d8a979ab0ed4b59000200000000000000000006': [
      {
        symbol: 'WTLOS-USDT',
        token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
        tokenSymbol: 'WTLOS',
        gauge: '0xa9d436ab58989354a5c3705f24a5130779055e47',
        rate: BigInt('1408695652173910000000') / BigInt('604800'),
        period_finish: 0,
        decimals: 18,
      },
    ],
    '0x058d4893efa235d86cceed5a7eef0809b76c8c66000000000000000000000004': [
      {
        symbol: 'USDC-USDT',
        token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
        tokenSymbol: 'WTLOS',
        gauge: '0xe4c412962fab7f2d406c43a8da95c68f9d60f24e',
        rate: BigInt('1056521739130430000000') / BigInt('604800'),
        period_finish: 0,
        decimals: 18,
      },
    ],
    '0x9a77bd2edbbb68173275cda967b76e9213949ace000000000000000000000008': [
      {
        symbol: 'STLOS-WTLOS',
        token: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
        tokenSymbol: 'WTLOS',
        gauge: '0xec678e92afe25bc5ba4bc2e1ff386c775270e49e',
        rate: BigInt('2817391304347830000000') / BigInt('604800'),
        period_finish: 0,
        decimals: 18,
      },
      {
        symbol: 'STLOS-WTLOS',
        token: '0x8f7D64ea96D729EF24a0F30b4526D47b80d877B9',
        tokenSymbol: 'USDM',
        gauge: '0xec678e92afe25bc5ba4bc2e1ff386c775270e49e',
        rate: BigInt('400000000000000000000') / BigInt('604800'),
        period_finish: 0,
        decimals: 18,
      },
    ],
  },
};
